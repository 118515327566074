





































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        { value: "name", text: root.$tc("layout.misc.name") },
        { text: "", value: "actions", align: "right", width: 200 },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
      },
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/sms-template`)
        .then(({ data }) => {
          state.empty = false;
          state.items = data;
          state.total = data.length;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`sms-template/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    return { state, fetchData, deleteItem };
  },
});
